const MOCK_FRONTEND_CLIENT_ID = "00000000-0000-0000-0000-000000000000";
const DEV_FRONTEND_CLIENT_ID = "762f8c63-6311-4c92-9229-2831601c2f4d";
const AME_FRONTEND_CLIENT_ID = "762f8c63-6311-4c92-9229-2831601c2f4d";
const FF_FRONTEND_CLIENT_ID = "a8326ed5-1f23-4c89-b22d-8ef40b06b6ab";
const MC_FRONTEND_CLIENT_ID = "553c6ed1-738a-4d96-9849-b200a527b7ce";
const FRONTEND_CLIENT_ID =
  process.env.NODE_ENV === "test"
    ? MOCK_FRONTEND_CLIENT_ID
    : process.env.NODE_ENV === "development"
    ? DEV_FRONTEND_CLIENT_ID
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ame"
      ? AME_FRONTEND_CLIENT_ID
      : process.env.REACT_APP_BUILD_MODE === "ff"
      ? FF_FRONTEND_CLIENT_ID
      : process.env.REACT_APP_BUILD_MODE === "mc"
      ? MC_FRONTEND_CLIENT_ID
      : DEV_FRONTEND_CLIENT_ID
    : MOCK_FRONTEND_CLIENT_ID;

const MOCK_AUTHORITY =
  "https://login.microsoftonline.test/00000000-0000-0000-0000-000000000000";
const DEV_AUTHORITY =
  "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";
const AME_AUTHORITY =
  "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";
const FF_AUTHORITY =
  "https://login.microsoftonline.us/cab8a31a-1906-4287-a0d8-4eef66b95f6e";
const MC_AUTHORITY =
  "https://login.partner.microsoftonline.cn/a55a4d5b-9241-49b1-b4ff-befa8db00269";
const AUTHORITY =
  process.env.NODE_ENV === "test"
    ? MOCK_AUTHORITY
    : process.env.NODE_ENV === "development"
    ? DEV_AUTHORITY
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ame"
      ? AME_AUTHORITY
      : process.env.REACT_APP_BUILD_MODE === "ff"
      ? FF_AUTHORITY
      : process.env.REACT_APP_BUILD_MODE === "mc"
      ? MC_AUTHORITY
      : DEV_AUTHORITY
    : MOCK_AUTHORITY;

export const msalConfig = {
  auth: {
    clientId: FRONTEND_CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const graphApiScopes = ["User.Read"];

const MOCK_BACKENDAPI = "api://00000000-0000-0000-0000-000000000000/Portal.Api";
let DEV_BACKENDAPI = "";

if (process.env.REACT_APP_PERSONNEL_BYPASS === "true") {
  DEV_BACKENDAPI = "api://a29aefbc-3d6b-43c7-83f6-59238b7c5ff8/portal-api";
} else {
  DEV_BACKENDAPI = "api://c63a96e8-31c9-4b03-b3fe-4bd9b484eb71/portal-api";
}

const AME_BACKENDAPI = "api://c63a96e8-31c9-4b03-b3fe-4bd9b484eb71/portal-api";
const FF_BACKENDAPI = "api://4dbe415e-772d-404c-a6b7-1d224626291f/Portal.Api";
const MC_BACKENDAPI = "api://3adc2ed7-7a95-4aa2-952d-520e6a501648/Portal.Api";

const BACKENDAPI =
  process.env.NODE_ENV === "test"
    ? MOCK_BACKENDAPI
    : process.env.NODE_ENV === "development"
    ? DEV_BACKENDAPI
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ame"
      ? AME_BACKENDAPI
      : process.env.REACT_APP_BUILD_MODE === "ff"
      ? FF_BACKENDAPI
      : process.env.REACT_APP_BUILD_MODE === "mc"
      ? MC_BACKENDAPI
      : DEV_BACKENDAPI
    : MOCK_BACKENDAPI;

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  backendApi: {
    scopes: [BACKENDAPI], // e.g. api://xxxxxx/access_as_user
  },
};
