import { useAccount, useMsal } from "@azure/msal-react";
import { Pivot, PivotItem } from "@fluentui/react";
import { protectedResources } from "authConfig";
import LoadingView from "flows/LoadingView";
import {
  baseDiv,
  card,
  errorTextStyle,
  essentialsColumn,
  essentialsGrid,
  essentialsTitle,
  headerDiv,
  overviewCard,
  pivotPadding,
  titleFont,
} from "flows/Styles";
import { createGridElements, showLocaleString } from "pipelines/admin/Pipeline";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
  callAuthorizedEndpointWithBody,
} from "utils/AuthorizedFetchCalls";
import { adminFlowAPI, adminRpAPI } from "utils/endpoints";
import RawView from "pipelines/admin/RawView";

const Flow = () => {
  const { subscriptionId, resourceGroup, connectionName, flowName } =
    useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [flow, setFlow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);

  const cachedInfo = useSelector(
    (state) => state.resourceProvider.admin_flows_list
  ).find(
    (flow) =>
      flow.id.toLowerCase() ===
      `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.AzureDataTransfer/connections/${connectionName}/flows/${flowName}`.toLowerCase()
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${adminFlowAPI}/${subscriptionId}/${resourceGroup}/${connectionName}/${flowName}`,
      token
    )
      .then((response) => response.json())
      .then((flow) => {
        setFlow(flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [
    selectedCloud,
    instance,
    account,
    subscriptionId,
    resourceGroup,
    connectionName,
    flowName,
  ]);

  useEffect(() => {
    if (cachedInfo) {
      setFlow(cachedInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedInfo, getFlow]);

  const syncFlowTypeValue = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${adminRpAPI}/${flow.properties.flowId}/sync`,
      token,
      "PATCH",
      flow
    )
      .then((response) => {
        if (response.status === 200) {
          alert("Sync successful");
        } else {
          alert("Failed to Sync");
        }
      })
      .catch((error) => {
        alert("Failed to Sync");
      });
  };

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{`[Flow] ${flowName}`}</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={overviewCard}>
            <div className={card}>
              <div className={essentialsTitle}>Connection Details</div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Name", flowName)}
                  {createGridElements("Resource Group", resourceGroup)}
                  {createGridElements("Subscription ID", subscriptionId)}
                  {createGridElements(
                    "Created",
                    showLocaleString(new Date(flow.systemData.createdAt))
                  )}
                </div>
              </div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Flow ID", flow.properties.flowId)}
                  {createGridElements("Flow Type", flow.properties.flowType)}
                  {createGridElements(
                    "Storage Account Name",
                    flow.properties.storageAccountName?.split("/").slice(-1)[0]
                  )}
                  {createGridElements(
                    "Storage Container Name",
                    flow.properties.storageContainerName
                  )}
                </div>
              </div>
            </div>
          </div>
        </PivotItem>
        <PivotItem headerText="JSON View">
          <RawView resource={flow} />
        </PivotItem>
        <PivotItem headerText="Sync FlowTypeValue">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <button
              onClick={syncFlowTypeValue}
              style={{
                padding: "20px 30px",
                fontSize: "2rem",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Sync FlowTypeValue
            </button>
          </div>
        </PivotItem>
      </Pivot>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow info" /> : content}
    </div>
  );
};

export default Flow;
